import firebase from 'firebase'
import React from 'react'
import EmailForm from './components/EmailForm'
import './App.css';

require('firebase/firestore')

firebase.initializeApp({
  apiKey: "AIzaSyAxvp5KcWzSE7a1UEYWLhHeq2WvwppNv5s",
  authDomain: "feelaliveio.firebaseapp.com",
  projectId: "feelaliveio",
  storageBucket: "feelaliveio.appspot.com",
  messagingSenderId: "80629584449",
  appId: "1:80629584449:web:8159fcc03c0871d9e4ecc0",
  measurementId: "G-F0FGEFRJM1"
});

function App() {
  let db = firebase.firestore();

  return (
    <div>
      <center>
        <div className="hero-text">
          <h1 className="brand">Alive</h1>
          <h2 className="hero">Analyze your health based on your Apple Health data.</h2>
          <p className="tag">Coming soon to iOS</p>
        </div>
        <EmailForm
          database = { db }
        />
      </center>
    </div>
  );
}

export default App;
