import React, { useState } from 'react'

import '../css/EmailForm.css';

const EmailForm = (props) => {
    const [email, setEmail] = useState('')
    const [emailSubmissions, setEmailSubmissions] = useState(0)

    const handleSubmitClick = (database) => {
        const emailRegex = '^[^@\s]+@[^@\s]+\.[^@\s]+$' 

        if (email.match(emailRegex) && emailSubmissions < 3) {
            database.collection("people").add({
                email: email,
            })
            .then(() => {
                let count = emailSubmissions
                count+=1
                setEmailSubmissions(count)
                setEmail('')                
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            })
        }
    }

    return (
        <div>
            <input className="textbox"
                type = 'text'
                placeholder = 'Email'
                value = { email }
                onChange = { ( { target: { value } } ) => setEmail(value) }
            />
            <br />
            <input className="submitbutton"
                type = 'submit'
                value = 'Request Access'
                onClick = { () => handleSubmitClick(props.database) }
            />
        </div>
    );
}

export default EmailForm;